<template>
<div class="home">
  <CContainer class="d-flex align-items-center min-vh-100">
    <CRow class="w-100 justify-content-center">
      <CCol md="6">
        <div class="w-100">
          <div class="clearfix">
            <h1>Sorry, the page you are looking for could not be found</h1>
            <p>Please double check the code entered and try again.</p>
          </div>
        </div>
      </CCol>
    </CRow>
  </CContainer>
  </div>
</template>

<script>
export default {
  name: "Page404",
};
</script>

<style scoped>
.home {
  background: top left no-repeat url("/assets/img/start_banner.jpg") transparent;
  background-size: cover;
  color: #fff;
}
</style>
